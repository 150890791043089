<!--
 * @Descripttion: 
 * @Version: 1.0
 * @Author: pj
 * @Date: 2023-03-27 10:24:57
 * @LastEditors: pj
 * @LastEditTime: 2023-05-10 14:30:03
-->
<template>
  <div>
    <gl-title title="核对订单信息" backIcon></gl-title>
    <div class="createOrder">
      <div class="createOrder-title">请选择收货地址</div>
      <div class="createOrder-addressList">
        <div
          class="createOrder-addressList-option"
          v-for="(item, index) in address"
          :key="index"
          @click="choiceAdress(index)"
        >
          <img
            :src="
              choiceIndex === index
                ? 'https://tanoshi.oss-cn-hangzhou.aliyuncs.com/coox/applets/20230328161502.png'
                : 'https://tanoshi.oss-cn-hangzhou.aliyuncs.com/coox/applets/20230328161518.png'
            "
            alt=""
            class="createOrder-addressList-option-img"
          />
          <div class="createOrder-addressList-option-top">
            <div>{{ item.name }}</div>
            <div>{{ item.phone }}</div>
          </div>
          <el-divider class="createOrder-addressList-option-line"></el-divider>
          <div
            class="createOrder-addressList-option-bottom"
            :title="item.addressFull"
          >
            {{ item.addressFull }}
          </div>
        </div>
      </div>
      <div class="createOrder-setAdress">
        <div
          class="createOrder-setAdress-button"
          @click="showToast('newAddress')"
        >
          使用新地址
        </div>
        <div
          class="createOrder-setAdress-button"
          @click="showToast('editAddress')"
        >
          修改此地址
        </div>
      </div>
      <div class="createOrder-title">请核对订单</div>

      <el-table :data="goodsInfo.orderQueryList" style="width: 100%">
        <el-table-column prop="skuPicture" label="封面" width="180">
          <template slot-scope="scope">
            <img
              :src="scope.row.skuPicture"
              alt=""
              style="width: 100px; height: 100px"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="itemName"
          label="批发商品"
          width="180"
        ></el-table-column>
        <el-table-column prop="skuName" label="款式"> </el-table-column>
        <el-table-column prop="skuSize" label="规格"> </el-table-column>
        <el-table-column prop="itemPrice" label="批发单价"> </el-table-column>
        <el-table-column prop="commission" label="手续费"> </el-table-column>
        <el-table-column prop="itemNum" label="数量"> </el-table-column>
        <el-table-column prop="totalPayMoney" label="合计"> </el-table-column>
      </el-table>

      <div class="createOrder-bottom">
        <div class="createOrder-bottom-title">
          <span class="createOrder-bottom-title-type1">实付款:</span>
          <span class="createOrder-bottom-title-type2"
            >¥{{ goodsInfo.totalPayMoney }}</span
          >
        </div>
        <div
          class="createOrder-bottom-button"
          @click="confirem"
          v-loading.fullscreen.lock="fullscreenLoading"
        >
          立即下订
        </div>
      </div>
    </div>

    <el-dialog
      title="收货地址"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="地址信息：" class="props-content" :required="true">
          <el-cascader
            v-model="value"
            :options="areaList"
            :props="{ value: 'label' }"
            @change="handleChange"
            style="width: 100%"
          ></el-cascader>
        </el-form-item>

        <el-form-item label="详细地址：" prop="addressDetail">
          <el-input type="textarea" v-model="form.addressDetail"></el-input>
        </el-form-item>

        <el-form-item label="收货姓名：" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>

        <el-form-item label="手机号码：" prop="phone">
          <el-input v-model.number="form.phone" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm('form')">取 消</el-button>
        <el-button type="primary" @click="submitForm('form')">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="微信支付"
      :visible.sync="dialogCanvas"
      width="30%"
      @close="handleClose"
    >
      <div
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <canvas ref="canvas"></canvas>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import {
  getListByUserId,
  orderAffirm,
  newAddress,
  updateAddress,
  orderPayInfoResult,
  orderPayInfo,
  newOrder,
  orderPayment,
} from '@/api/coox/shop.js'

import { areaList, useCascaderAreaData } from '@/utils/area-list.js'

import QRCode from 'qrcode'

// import { findLabels } from '@/utils/breadth-traversal.js'

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  props: {},
  data() {
    // 这里存放数据
    return {
      timer: null,
      goodsInfo: {},
      address: [],
      dialogFormVisible: false,
      dialogCanvas: false,
      fullscreenLoading: false,
      form: {
        addressDetail: '',
        city: '',
        isDefault: 1,
        name: '',
        phone: '',
        province: '',
        region: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入名字', trigger: 'blur' },
          {
            min: 1,
            max: 10,
            message: '长度在 1 到 10 个字符',
            trigger: 'blur',
          },
        ],
        phone: [
          { required: true, message: '电话不能为空' },
          { type: 'number', message: '必须为数字值' },
        ],
        addressDetail: [
          { required: true, message: '请填写详细地址', trigger: 'blur' },
        ],
      },
      areaList: [],
      value: [],
      choiceIndex: 0,
      type: null,
    }
  },
  // 计算属性类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    async setAddress() {
      const results = await getListByUserId()
      this.address = results.data.rows
    },
    showToast(target) {
      if (target === 'newAddress') {
        this.type = 0
        this.dialogFormVisible = true
      } else {
        this.type = 1
        this.form = { ...this.address[this.choiceIndex] }
        this.form.phone = Number(this.form.phone)
        this.value = [this.form.province, this.form.city, this.form.region]
        this.dialogFormVisible = true
      }
    },
    handleChange(value) {
      console.log(value)
      // console.log(findLabels(this.areaList, value))
    },
    submitForm(formName) {
      let that = this
      if (!this.value) {
        this.$alert('必须选择地址信息', '错误', {
          confirmButtonText: '确定',
          callback: (action) => {},
        })
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const params = {
            ...this.form,
          }
          params.province = this.value[0]
          params.region = this.value[2]
          params.city = this.value[1]

          if (that.type == 0) {
            const result = await newAddress(params)
            if (result.code == 200) {
              this.setAddress()
            }
          } else {
            const result = await updateAddress(params)
            if (result.code == 200) {
              this.setAddress()
            }
          }
          this.dialogFormVisible = false
        } else {
          return false
        }
      })
    },
    resetForm(formName) {
      this.value = []
      this.$refs[formName].resetFields()
      this.dialogFormVisible = false
    },
    choiceAdress(target) {
      this.choiceIndex = target
      console.log(this.choiceIndex, 'this.choiceIndex')
    },
    handleClose() {
      window.clearInterval(this.timer)
      this.timer = null
    },

    async confirem() {
      console.log(this.address)
      if (this.address.length == 0) {
        this.$message({
          message: '没有地址',
          type: 'warning',
        })
        return
      }
      this.fullscreenLoading = true
      let that = this
      const orderQueryList = []
      that.goodsInfo.orderQueryList.forEach((element) => {
        element.name = that.address[that.choiceIndex].name
        element.phone = that.address[that.choiceIndex].phone
        element.province = that.address[that.choiceIndex].province
        element.city = that.address[that.choiceIndex].city
        element.region = that.address[that.choiceIndex].region
        element.addressDetail = that.address[that.choiceIndex].addressDetail
        element.orderType = this.$route.query.type == 0 ? 2 : 3
        orderQueryList.push(element)
      })
      const params = {
        orderQueryList: orderQueryList,
        cartIds: that.goodsInfo.cartIds || [],
      }
      try {
        const result = await newOrder(params)
        if (result.code !== 200) throw new Error('订单建立失败')
        const orderIds = result.data
        const result2 = await orderPayInfo(orderIds)
        if (result2.code !== 200) throw new Error('获取支付订单信息失败')
        const params2 = result2.data
        params2['payType'] = 23
        const result3 = await orderPayment(params2)
        if (result3.code !== 200) throw new Error('下单支付失败')
        const paymentParams = JSON.parse(result3.data.notifyResult)
        that.dialogCanvas = true
        this.fullscreenLoading = false
        this.$nextTick(() => {
          console.log(that.$refs.canvas, paymentParams)
          QRCode.toCanvas(this.$refs.canvas, paymentParams)
          const params = {
            orderIds: orderIds,
          }
          that.timer = setInterval(async () => {
            // 发送请求，获取支付结果
            console.log('轮训中')
            const result = await orderPayInfoResult(params)
            if (result.data == 2) {
              that.$message({
                message: '支付成功',
                type: 'success',
              })
              that.$router.replace({
                path: '/main/cool/goodsManagement/order',
              })
              clearInterval(paid)
            } else if (
              result.data == 3 ||
              result.data == 4 ||
              result.data == -1
            ) {
              that.$message({
                message: '支付失败',
                type: 'warning',
              })
              that.timer = null
              that.$router.back()
              clearInterval(that.timer)
            }
          }, 3000)
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  async created() {
    const params = {
      cartItemDTOS: [
        {
          itemId: this.$route.query.itemId,
          skuId: this.$route.query.skuId,
          num: this.$route.query.num,
        },
      ],
      orderType: this.$route.query.type == 0 ? 2 : 3,
    }
    console.log(params, 'paramsparamsparamsparams')
    const result = await orderAffirm(params)
    this.goodsInfo = result.data
    this.setAddress()
    const areaData = useCascaderAreaData()
    this.areaList = areaData
    console.log(areaData, 'areaData')
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {
    window.clearInterval(this.timer)
    this.timer = null
  }, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="less" scoped>
.createOrder {
  width: 1100px;
  height: 817px;
  border-radius: 6px;
  opacity: 1;
  background: #ffffff;
  box-sizing: border-box;
  margin: 30px 0 0 30px;
  padding: 30px;
  &-title {
    font-size: 14px;
    line-height: 18px;
    color: #878787;
    margin-bottom: 10px;
  }
  &-addressList {
    display: flex;
    flex-wrap: wrap;
    &-option {
      width: 224px;
      height: 101px;
      border-radius: 6px;
      position: relative;
      margin-right: 10px;
      &-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 224px;
        height: 101px;
      }
      &-top {
        position: absolute;
        top: 10px;
        left: 15px;
        display: flex;
        justify-content: space-between;
        width: 200px;
        font-size: 11px;
        line-height: 18px;
        color: #878787;
      }
      &-line {
        position: absolute;
        top: 12px;
        left: 15px;
        width: 200px;
      }

      &-bottom {
        position: absolute;
        top: 42px;
        left: 15px;
        width: 200px;
        font-size: 11px;
        line-height: 18px;
        color: #878787;
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }

  &-setAdress {
    display: flex;
    margin-top: 25px;
    margin-bottom: 37px;
    &-button {
      width: 85px;
      height: 26px;
      border-radius: 3px;
      background: #ffffff;
      box-sizing: border-box;
      border: 1px solid #d8d8d8;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      font-size: 11px;
      line-height: 18px;
      color: #878787;
    }
  }
  &-bottom {
    margin: 170px 30px 0 790px;
    &-title {
      margin-bottom: 20px;
      text-align: right;
      &-type1 {
        font-size: 12px;
        line-height: 18px;
        color: #3d3d3d;
        margin-right: 10px;
      }
      &-type2 {
        font-size: 32px;
        line-height: 18px;
        color: #3d3d3d;
      }
    }

    &-button {
      width: 250px;
      height: 50px;
      border-radius: 10px;
      opacity: 1;
      background: #409eff;
      box-shadow: 0px 4px 10px 0px rgba(64, 158, 255, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
      color: #ffffff;
    }
  }
}
/deep/.el-form-item {
  display: flex;
}
/deep/ .el-form-item__content {
  width: 80%;
}
</style>
